<template>
  <div id="disclaimer-wrapper">
          <!-- class="py-2 px-4" -->
    <v-tooltip left>
      <template v-slot:activator="{ on }">
        <v-card
          v-on="on"
          id="disclaimer"
          class="py-1 px-1"
          color="rgba(0, 0, 0, .3)"
          dark
          flat
          link
          @click="loadDisclaimer"
          min-width="80"
          style="position: fixed; top: 270px; right: -35px; border-radius: 25px; z-index: 1300;"
        >
          <v-icon large>
            mdi-information
          </v-icon>
        </v-card>
      </template>
      <span>{{ $t("front.disclaimerTooltip")}}</span>
    </v-tooltip>
<!-- 
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      activator="#disclaimer"
      bottom
      content-class="v-settings"
      left
      nudge-left="8"
      offset-x
      origin="top right"
      transition="scale-transition"
      z-index=1300
    >

      <v-dialog
        v-model="menu"
        overlay-opacity="0.7"
        style="z-index: 1310"
      >
        <v-card
          class="text-center mb-0"
          width="100%"
          height="100%"
          style="z-index:1300;"
        >
          <v-card-title class="mb-2 justify-center" style="background-color: #d9c7A3">
            <v-spacer />
            {{ $t('disclaimer.title')}}
             <v-spacer /> <v-btn icon @click.stop="menu=false"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-card-text>
            <br>
            <p class="text-left">
              By visiting this website, the user accepts in full the terms of this disclaimer. All information posted on this website is published in good faith, provided for information purposes for the general public as well as for the scientific and research purposes of the LIFE project "Egyptian Vulture New LIFE" (LIFE16 NAT/BG/000874). The locations depicted on the map are not associated with a particular landowner, neither it is implied or attributed any offending behavior to specific persons or groups of persons.
            </p>

            <p class="text-left">
              While every effort is made to ensure that the information provided on this site is accurate and up-to-date, Project partners do not provide any form of guarantee, explicit or implicit, regarding the completeness, accuracy or reliability of the information contained on the site for any purpose. Any use of this information is at the sole responsibility of the user. By providing information on this site, Project partners do not prompt visitors for any act or omission and in no event accept any liability for direct or indirect damage that may result from the use of the site's information. In the event that the user navigates through hyperlinks to other sites while browsing this site, we here state that Project partners do not control the nature, content and availability of these sites.
            </p>

            <p class="text-left">
              By displaying any hyperlinks on the site does not imply recommending or approving the content of these. Project partners strive for the site's correct functioning, but they do not assume responsibility for any impediment to its operation due to technical or other reasons.Any changes to this statement will be announced here.
            </p>
          </v-card-text>

          <v-card-actions>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-menu> -->
  </div>
</template>

<script>
  // Mixins
  import Proxyable from 'vuetify/lib/mixins/proxyable'

  export default {
    name: 'DisclaimerInfo',

    mixins: [Proxyable],

    data: () => ({
      menu: false,
    }),
    methods: { 
      loadDisclaimer () {
        this.$emit('showDisclaimer', true)
      }
    }
  }
</script>

// <style lang="sass">
//   .v-settings
//     .v-item-group > *
//       cursor: pointer
        
//     &__item
//       border-width: 3px
//       border-style: solid
//       border-color: transparent !important

//       &--active
//         border-color: #00cae3 !important
// </style>

<style scoped>
.v-tooltip__content {
  font-size: 15px !important;
  opacity: 1 !important;
  z-index: 1400 !important;
  /* display: block !important; */
}
</style>